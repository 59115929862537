import {ImageCropperInstance, ImageCropperOptions} from "./types";
import Cropper from "cropperjs";
import 'cropperjs/dist/cropper.css';

const Default : ImageCropperOptions = {
    width: 192,
    height: 192,
    viewMode: 3,
    onValidate: () => {},
}

class ImageCropper {
    _targetEl : HTMLImageElement;
    _triggerEl: HTMLElement;
    _options : ImageCropperOptions;
    _cropper: Cropper;

    constructor(
        targetEl: HTMLImageElement,
        triggerEl: HTMLElement,
        options: ImageCropperOptions = Default
    ) {
        this._targetEl = targetEl;
        this._triggerEl = triggerEl;
        this._options = {...Default, ...options}
        this._init();
    }

    /**
     * Initialisation du cropper
     */
    _init () {
        this._cropper = new Cropper(this._targetEl, {
            viewMode: this._options.viewMode,
            dragMode: 'move',
            restore: false,
            guides: false,
            center: false,
            highlight: false,
            cropBoxMovable: true,
            cropBoxResizable: false,
            toggleDragModeOnDblclick: false,
            data:{ //define cropbox size
                width: this._options.width,
                height:  this._options.height,
            },
        });
    }

    /**
     * Lorsque le crop est validé
     */
    validate () {

        const inputTargetId = this._triggerEl.getAttribute('data-image-cropper-validation-target');
        const inputTargetEl = document.getElementById(inputTargetId) as HTMLInputElement;

        if(inputTargetEl) {
            //Conversion du canvas en objet file
            const canvas = this._cropper.getCroppedCanvas()
            canvas.toBlob((blob) => {
                const file = new File([blob], 'img.jpg', {type: 'image/jpeg', lastModified: new Date().getTime()})
                const container = new DataTransfer()
                container.items.add(file);

                // Injecte l'objet fichier généré dans l'input
                inputTargetEl.files = container.files
                inputTargetEl.dispatchEvent(new Event('change'));

                const i = document.querySelector('#inputFile') as HTMLInputElement;
                i.files = new DataTransfer().files;

            }, 'image/jpeg', 1)

        } else {
            console.error(
                `L'instance du HTMLInputElement pour la validation de l'image cropper avec l'id  ${inputTargetId} n'existe pas.
                Êtes-vous sûr que l'attribut data-image-cropper-validation-target pointe vers le bon identifiant ?`
            );
        }

        this._options.onValidate()
    }
}

if(typeof window !== undefined) {
    window.ImageCropper = ImageCropper;
}

const getImageCropperInstance = (id: string, instances: ImageCropperInstance[]) => {
    if (instances.some((drawerInstance) => drawerInstance.id === id)) {
        return instances.find((drawerInstance) => drawerInstance.id === id);
    }
};

/**
 * Initialisation des croppers pour les images
 */
export function initImageCroppers () {
    const imageCropperInstances = [] as ImageCropperInstance[];

    /* Initialisation des croppers */
    document.querySelectorAll('[data-image-cropper-target]').forEach($triggerEl => {
        const imageCropperId = $triggerEl.getAttribute('data-image-cropper-target');
        const $imageCropperEl = document.getElementById(imageCropperId) as HTMLImageElement;

        const options = {};
        if($triggerEl.getAttribute('data-image-cropper-width')) {
            options['width'] = $triggerEl.getAttribute('data-image-cropper-width')
        }

        if($triggerEl.getAttribute('data-image-cropper-height')) {
            options['height'] = $triggerEl.getAttribute('data-image-cropper-height')
        }

        if($triggerEl.getAttribute('data-image-cropper-view-mode')) {
            options['viewMode'] = $triggerEl.getAttribute('data-image-cropper-view-mode')
        }

        // Si l'élément existe
        if($imageCropperEl) {
            // Enregistre l'instance du cropper
            if(!getImageCropperInstance(imageCropperId, imageCropperInstances)) {
                imageCropperInstances.push({
                    id: imageCropperId,
                    object: new ImageCropper(
                        $imageCropperEl,
                        $triggerEl as HTMLElement,
                        options as ImageCropperOptions
                    )
                })
            }
        }
        // else {
        //     console.error(
        //         `L'instance ImageCropper avec l'id  ${imageCropperId} n'existe pas.
        //         Êtes-vous sûr que l'attribut data-image-cropper pointe vers le bon identifiant ?`
        //     );
        // }
    });

    /* Lorsque le crop est validé */
    document.querySelectorAll('[data-image-cropper-validation]').forEach($triggerEl => {
        const imageCropperId = $triggerEl.getAttribute('data-image-cropper-validation');
        const $imageCropperEl = $triggerEl as HTMLImageElement;

        if($imageCropperEl) {

            // Instance de l'image cropper
            const imageCropper: ImageCropperInstance = getImageCropperInstance(
                imageCropperId,
                imageCropperInstances
            );

            // Si l'instance existe, bind le click du bouton sur la fonction validate
            if(imageCropper) {
                $imageCropperEl.addEventListener('click', () => {
                    imageCropper.object.validate();
                })
            }
        }
    });
}

export default ImageCropper;
