import {ToastOptions} from "./types";

const Default : ToastOptions = {
    classes: 'duration-700 opacity-0'
}

class Toast {

    _targetEl : HTMLElement;
    _options : ToastOptions;
    _timer;

    constructor(
        targetEl: HTMLElement,
        options: ToastOptions = Default
    ) {
        this._targetEl = targetEl;
        this._options = {...Default, ...options}
        this._init()
    }

    _init () {
        this._timer = setTimeout(this.hide.bind(this), 2000)
    }

    hide () {
        this._targetEl.classList.add('translate-x-full', 'opacity-0');
        setTimeout(() => {
            this._targetEl.remove();
            clearTimeout(this._timer)
        }, 300)
    }
}

if(typeof window !== undefined) {
    window.Toast = Toast;
}

export function initToasts () {
    document.querySelectorAll('[data-toast]').forEach($triggerEl => {
        const $targetEl = $triggerEl;
        new Toast($targetEl as HTMLElement);
    });
}

export default Toast;
