// @ts-ignore
import.meta.glob([
    '../static/**',
])

import './bootstrap';

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

console.log(window.Alpine)

import {initModals} from "flowbite";

import ImageCropper, {initImageCroppers} from "./components/image-cropper";
import {initToasts} from "./components/toast";
import Events from './dom/events'

const events = new Events('load', [
    initToasts,
])

events.init()

/* Ecouteurs Livewire */
// @ts-ignore
Livewire.on('initModals', () => {
    //console.log('initModal');
    initModals();
})

// @ts-ignore
Livewire.on('initImageCroppers', () => {
    //console.log('initImageCroppers');
    initImageCroppers();
})

/* Cas particulier */
const header = document.querySelector('#home-header');
if(header) {
    window.addEventListener('scroll', (e) => {
        if(window.scrollY > window.innerHeight + 61) {
            header.classList.remove('-translate-y-full')
            header.classList.remove('hidden')
        } else {
            header.classList.add('-translate-y-full');
            header.classList.add('hidden');
        }
    });
}


// Gestion des cookies
import "vanilla-cookieconsent/dist/cookieconsent.css"
import * as CookieConsent from "vanilla-cookieconsent";
import { fr } from './lang/cookieconsent/fr'

CookieConsent.run({
    revision: 0,
    guiOptions: {
        consentModal: {
            layout: 'cloud',
            position: 'bottom left',
            flipButtons: false,
            equalWeightButtons: true
        },
    },

    onConsent: function () {
        // Consentement ok
    },

    onChange: function () {
        // Changement du consentement
    },

    categories: {
        analytics: {
            enabled: false,
            readOnly: false,
            autoClear: {
                cookies: [
                    {name: /^_ga/ },   // regex: match all cookies starting with '_ga' Google
                    { name: /^__ut/ }, // regex: match all cookies starting with '__ut' Google
                    { name: '_gid'},   // string: exact cookie name Google
                    { name: 't_gid'}, // Taboola
                    { name: 'auth_token' }, // Twitter
                    { name: 'dnt' }, // Twitter
                    { name: 'guest_id' }, // Twitter
                    { name: 'personalization_id' }, // Twitter
                    { name: 'personalization_id', path: '/', domain: 'twitter.com' }, // Twitter
                    { name: 'personalization_id', path: '/', domain: 'www.twitter.com' }, // Twitter
                ]
            }
        }
    },

    language: {
        default: 'fr',
        translations: {
            fr: fr
        }
    }
})
