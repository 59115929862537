export const fr = {
    consentModal: {
        title: 'Cookies',
        description: 'Ce site utilise des cookies permettant de visualiser des contenus et d\'améliorer le ' +
            'fonctionnement grâce aux statistiques de navigation. Si vous cliquez sur « Accepter », CGSKILLED ' +
            '(éditeur du site cgskilled.com) et ses partenaires déposeront ces cookies sur votre terminal lors ' +
            'de votre navigation. Si vous cliquez sur « Refuser », ces cookies ne seront pas déposés. ' +
            'Votre choix est conservé pendant 12 mois et vous pouvez être informé et modifier vos préférences ' +
            'à tout moment en cliquant sur « Gérer les cookies ». en bas de page.',
        acceptAllBtn: 'Accepter',
        acceptNecessaryBtn: 'Refuser',
        showPreferencesBtn: 'Gérer les cookies'
    },
    preferencesModal: {
        title: 'Gestion des cookies',
        acceptAllBtn: 'Tout accepter',
        acceptNecessaryBtn: 'Tout refuser',
        savePreferencesBtn: 'Sauvegarder ma sélection',
        closeIconLabel: 'Fermer',
        sections: [
            {
                title: '',
                description: 'Un cookie est un fichier texte déposé sur votre ordinateur lors de votre visite ' +
                    'sur le site. Il permet de conserver vos données de navigation. Certaines fonctionnalités ' +
                    'listées ci-dessous s’appuient sur des services proposés par des tiers. Si vous donnez ' +
                    'votre accord (consentement), ces tiers déposeront des cookies qui permettront de ' +
                    'visualiser directement sur cgskilled.com du contenu hébergé par ces tiers, de partager nos ' +
                    'contenus et d’améliorer le site grâce aux statistiques de navigation. Via ces cookies, ' +
                    'certains de ces tiers collecteront et utiliseront vos données de navigation pour des ' +
                    'finalités qui leur sont propres, conformément à leur politique de confidentialité. ' +
                    'Cette page vous permet de donner ou de retirer votre consentement à tout moment, ' +
                    'soit globalement soit par finalité. Le site cgskilled.com utilise également des ' +
                    'services de mesure d\'audience permettant de générer des statistiques anonymes utiles ' +
                    'à l\'amélioration du site que vous pouvez désactiver à tout moment sur cette page. ' +
                    'Pour en savoir plus, merci de lire notre <a href="https://cgskilled.com/help-center/page/politique-de-confidentialite" title="politique de confidentialité">politique de confidentialité</a>.'
            },
            {
                title: 'Mesure d\'audience et d\'utilisation du site',
                description: 'Ils permettent d’obtenir des statistiques de fréquentation anonymes du site ' +
                    'afin d’optimiser son ergonomie, sa navigation et ses contenus.',
                linkedCategory: 'analytics'
            }
        ]
    }
}
